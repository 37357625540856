import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const platform: RouteRecordRaw = {
  path: '/platform',
  component: LayoutComponent,
  redirect: '/platform/organization/organization',
  children: [
    {
      path: '/platform/resources/menu',
      meta: {
        title: '平台菜单',
      },
      component: () => import('../pages/platform/resources/menu/menu-index.vue'),
    },
    {
      path: '/platform/resources/organization',

      meta: {
        title: '组织菜单',
      },
      component: () => import('../pages/platform/resources/organization/organization-index.vue'),
    },
    {
      path: '/platform/resources/product-package',
      meta: {
        title: '产品包管理',
      },
      component: () =>
        import('../pages/platform/resources/product-package/product-package-index.vue'),
    },
    {
      path: '/platform/resources/resource-list',
      meta: {
        title: '资源列表',
      },
      component: () => import('../pages/platform/resources/resource-list/resource-list-index.vue'),
    },
    {
      path: '/platform/resources/default-role',
      meta: {
        title: '默认角色',
      },
      component: () => import('../pages/platform/resources/default-role/default-role-index.vue'),
    },
    {
      path: '/platform/user/staff',
      meta: {
        title: '员工管理',
      },
      component: () => import('../pages/platform/user/staff/staff-index.vue'),
    },
    {
      path: '/platform/user/role',
      meta: {
        title: '角色管理',
      },
      component: () => import('../pages/platform/user/role/role-index.vue'),
    },
    {
      path: '/platform/organization/organization',
      meta: {
        title: '组织管理',
      },
      component: () => import('../pages/platform/organization/organization/organization-index.vue'),
    },
    {
      path: '/platform/organization/user',
      meta: {
        title: '用户管理',
      },
      component: () => import('../pages/platform/organization/user/user-index.vue'),
    },
    {
      path: '/platform/operations/material-manual-matching',
      meta: {
        title: '物料手动匹配',
      },
      component: () =>
        import(
          '../pages/platform/operations/material-manual-matching/material-manual-matching-index.vue'
        ),
    },
    {
      path: '/platform/operations/coding-rule',
      meta: {
        title: '编码规则',
      },
      component: () => import('../pages/platform/operations/coding-rule/coding-rule-index.vue'),
    },
  ],
}
