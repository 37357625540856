<template>
  <nav ref="navRef" class="sc-nav-tab" @mouseover="holding = true" @mouseleave="holding = false">
    <div class="tab-wrapper" :style="computedStyle">
      <a-dropdown trigger="contextmenu">
        <a
          class="tab-item"
          :class="{ active: current?.path === '/home/index' }"
          @click="
            pushTab({
              path: '/home/index',
              name: '首页',
            })
          "
          title="首页"
        >
          <p>首页</p>
          <a-divider v-show="panes.length" type="vertical" />
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="handleRemoveOtherTabs('/home/index')">关闭其他页面</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a-dropdown v-for="(tag, i) in panes" :key="tag.path" trigger="contextmenu">
        <a
          class="tab-item"
          :class="{ active: current?.path === tag.path }"
          @click="pushTab(tag)"
          :title="tag.name"
        >
          <p>{{ getTabName(tag) }}</p>
          <close-outlined @click.prevent.stop="handleTabRemove(tag.path, i)" />
          <a-divider
            type="vertical"
            v-show="current?.path !== tag.path && i !== panes.length - 1"
          />
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item v-if="tag.path !== '/home/index'" @click="handleTabRemove(tag.path, i)"
              >关闭</a-menu-item
            >
            <a-menu-item @click="handleRemoveOtherTabs(tag.path)">关闭其他页面</a-menu-item>
            <a-menu-item v-if="tag.path !== '/home/index'" @click="handleRemoveToRight(i)"
              >关闭右侧页面</a-menu-item
            >
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { CloseOutlined } from '@ant-design/icons-vue'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch, nextTick } from 'vue'
import { useNavTab } from '../../store'

// 路由黑名单
const blackList = [
  '/supplier/resource-pool/main-data/',
  '/supplier/process/life-cycle/manage/upgrade/',
  '/supplier/process/life-cycle/manage/downgrade/',
]

const navTab = useNavTab()
const { pushTab, removeTab } = navTab
const { current, tabs } = storeToRefs(navTab)
const panes = computed(() =>
  tabs.value.data
    .filter(t => {
      return t.path !== '/home/index'
    })
    .filter(t => {
      return !blackList.some(i => t.path.includes(i))
    }),
)
const getTabName = (tag: (typeof panes.value)[0]) => {
  if (tag.path === '/home/index') {
    return '首页'
  }
  return tag.name
}

watch(
  () => current.value,
  () => {
    if (current.value.name) {
      document.title = `${
        current.value.name !== '未知页面' ? current.value.name : '首页'
      } - 睿朴麟科技`
    }
  },
  { deep: true, immediate: true },
)
const defaultMaxWidth = window.innerWidth - 210
const holding = ref(false)
const maxWidth = ref(defaultMaxWidth)
const tabWidth = ref(35)
const navRef = ref<HTMLDivElement>()
const computedStyle = computed(() => {
  const w = holding.value
    ? tabWidth.value * (panes.value.length + 1)
    : maxWidth.value || defaultMaxWidth
  return {
    width: `${w}px`,
    transition: holding.value ? 'none' : 'width 0.3s ease',
  }
})

const handleTabRemove = (path: string, index: number) => {
  removeTab(path)
  nextTick(() => {
    if (index === panes.value.length) {
      tabWidth.value = maxWidth.value / panes.value.length
    }
  })
}

const handleRemoveOtherTabs = (path: string) => {
  const tabsToRemove = panes.value.filter(item => item.path !== path)
  tabsToRemove.forEach(item => {
    removeTab(item.path)
  })
}

const handleRemoveToRight = (index: number) => {
  const tabsToRemove = panes.value.slice(index + 1)
  tabsToRemove.forEach(item => {
    removeTab(item.path)
  })
}

watch(holding, val => {
  if (val) {
    tabWidth.value = maxWidth.value / panes.value.length
  }
})

const setMaxWidth = () => {
  if (navRef.value) {
    maxWidth.value = navRef.value.offsetWidth - 30
  }
}

onMounted(() => {
  setMaxWidth()
  window.addEventListener('resize', setMaxWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', setMaxWidth)
})
</script>
