import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const workbench: RouteRecordRaw = {
  path: '/workbench',
  component: LayoutComponent,
  redirect: '/workbench/home',
  children: [
    {
      path: '/workbench/home',
      meta: { title: '首页' },
      component: () => import('../pages/home/home-index.vue'),
    },
    {
      path: '/workbench/my-task/initiated',
      meta: { title: '我的发起' },
      component: () => import('../pages/workbench/my-task/my-launch/my-launch-index.vue'),
    },
    {
      path: '/workbench/my-task/to-do',
      meta: { title: '我的待办' },
      component: () => import('../pages/workbench/my-task/my-backlog/my-backlog-index.vue'),
    },
    {
      path: '/workbench/my-task/done',
      meta: { title: '我的已办' },
      component: () =>
        import('../pages/workbench/my-task/my-have-finished/my-have-finished-index.vue'),
    },
    {
      path: '/workbench/my-task/cc-me',
      meta: { title: '抄送我的' },
      component: () => import('../pages/workbench/my-task/my-carbon-copy/my-carbon-copy-index.vue'),
    },
  ],
}
