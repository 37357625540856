import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const settlement: RouteRecordRaw = {
  path: '/settlement',
  component: LayoutComponent,
  redirect: '/settlement/settlement-pool',
  children: [
    {
      path: '/settlement',
      meta: { title: '结算管理' },
      children: [
        {
          path: '/settlement/settlement-pool',
          meta: { title: '结算池' },
          component: () =>
            import('../pages/settlement/settlement/settlement-pool/settlement-pool-index.vue'),
        },
        {
          path: '/settlement/reconciliation-workbench',
          meta: { title: '对账工作台' },
          component: () =>
            import('../pages/settlement/settlement/reconciliation-workbench/index.vue'),
        },
        {
          path: '/settlement/settlement-workbench',
          meta: { title: '结算工作台' },
          component: () => import('../pages/settlement/settlement/settlement-workbench/index.vue'),
        },
        {
          path: '/settlement/workbench',
          meta: { title: '结算工作' },
          component: () =>
            import('../pages/settlement/settlement/reconciliation-workbench/supplier.vue'),
        },
      ],
    },
  ],
}
