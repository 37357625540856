import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const buildShare: RouteRecordRaw = {
  path: '/build-share',
  component: LayoutComponent,
  redirect: '/build-share/share/platform/eb-level-set',
  children: [
    {
      path: '/build-share/share/platform/eb-level-set',
      meta: { title: 'EB等级设置' },
      component: () => import('../pages/build-share/share/platform/level-set/eb-level-set.vue'),
    },
    {
      path: '/build-share/share/platform/dfm-level-set',
      meta: { title: 'DFM等级设置' },
      component: () => import('../pages/build-share/share/platform/level-set/dfm-level-set.vue'),
    },
    {
      path: '/build-share/share/platform/order',
      meta: { title: '模型订单列表' },
      component: () => import('../pages/build-share/share/platform/order/order-index.vue'),
    },
    {
      path: '/build-share/share/organization/order',
      meta: { title: '订单列表' },
      component: () => import('../pages/build-share/share/organization/order/order-index.vue'),
    },
    {
      path: '/build-share/share/platform/modal-order',
      meta: { title: '模型订单明细' },
      component: () => import('../pages/build-share/share/platform/model-order/modal-order.vue'),
    },
    {
      path: '/build-share/share/organization/modal-order',
      meta: { title: '模型订单明细' },
      component: () =>
        import('../pages/build-share/share/organization/model-order/modal-order.vue'),
    },
    {
      path: '/build-share/share/organization/shop-cart',
      meta: { title: '愿望清单' },
      component: () => import('../pages/build-share/share/organization/shop-cart/shop-cart.vue'),
    },
    {
      path: '/build-share/share/organization/order-settlement',
      meta: { title: '订单结算' },
      component: () =>
        import('../pages/build-share/share/organization/order-settlement/order-settlement.vue'),
    },
    {
      path: '/build-share/share/platform/fire-coin-set',
      meta: { title: '火币账户' },
      component: () =>
        import('../pages/build-share/share/platform/fire-coin-set/fire-coin-set.vue'),
    },
    {
      path: '/build-share/share/organization/fire-coin-account',
      meta: { title: '火币账户' },
      component: () =>
        import('../pages/build-share/share/organization/fire-coin-account/fire-coin-account.vue'),
    },
    {
      path: '/build-share/share/platform/fire-coin-flow',
      meta: { title: '火币流水记录' },
      component: () =>
        import('../pages/build-share/share/platform/fire-coin-flow/fire-coin-flow.vue'),
    },
    {
      path: '/build-share/share/organization/fire-coin-flow',
      meta: { title: '火币流水记录' },
      component: () =>
        import('../pages/build-share/share/organization/fire-coin-flow/fire-coin-flow.vue'),
    },
    {
      path: '/build-share/share/platform/recharge-record',
      meta: { title: '火币充值记录' },
      component: () =>
        import('../pages/build-share/share/platform/recharge-record/recharge-record-index.vue'),
    },
    {
      path: '/build-share/share/organization/recharge-record',
      meta: { title: '火币充值记录' },
      component: () =>
        import('../pages/build-share/share/organization/recharge-record/recharge-record-index.vue'),
    },
    {
      path: '/build-share/share/organization/public-list',
      meta: { title: '公库记录' },
      component: () =>
        import('../pages/build-share/share/organization/public-list/public-list-index.vue'),
    },
    {
      path: '/build-share/share/organization/public-category',
      meta: { title: '公库品类' },
      component: () =>
        import('../pages/build-share/share/organization/public-category/public-category-index.vue'),
    },
    {
      path: '/build-share/share/organization/public-brand',
      meta: { title: '公库品牌' },
      component: () =>
        import('../pages/build-share/share/organization/public-brand/public-brand-index.vue'),
    },
    {
      path: '/build-share/share/organization/public-series',
      meta: { title: '公库系列' },
      component: () =>
        import('../pages/build-share/share/organization/public-series/public-series-index.vue'),
    },
    {
      path: '/design/export-wire-list',
      meta: { title: '导出下线表' },
      component: () => import('../pages/design/export-wire-list/export-wire-list.vue'),
    },
  ],
}
