import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const customer: RouteRecordRaw = {
  path: '/customer',
  component: LayoutComponent,
  redirect: '/customer',
  children: [
    {
      path: '/customer/invitation-management',
      meta: { title: '邀约管理' },
      children: [
        {
          path: '/customer/invitation-management',
          meta: { title: '邀约管理' },
          component: () =>
            import('../pages/customer/invitation-management/invitation-management.vue'),
        },
      ],
    },
  ],
}
