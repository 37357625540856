import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { Organization } from '@/types/common'

export const useOrganization = defineStore('organization', () => {
  let defaultOrganization = []
  let defaultOrganizationId
  let defaultIsPlatform = false
  let defaultIsSeller = false
  let defaultOrganizationAppendTypeId = ''

  // 组织列表
  if (localStorage.getItem('organizationList')) {
    defaultOrganization = JSON.parse(localStorage.getItem('organizationList') || '[]')
  }

  // 组织id
  if (localStorage.getItem('organizationId')) {
    defaultOrganizationId = JSON.parse(localStorage.getItem('organizationId') || '0')
  }

  // 平台用户
  if (localStorage.getItem('isPlatformUser')) {
    defaultIsPlatform = JSON.parse(localStorage.getItem('isPlatformUser') || 'false')
  }

  // Seller用户
  if (localStorage.getItem('isSellerUser')) {
    defaultIsSeller = JSON.parse(localStorage.getItem('isSellerUser') || 'false')
  }

  // 组织类型 0-采购组织 1-供应商组织 2-平台组织
  if (localStorage.getItem('organizationAppendTypeId')) {
    defaultOrganizationAppendTypeId = localStorage.getItem('organizationAppendTypeId') || ''
  }

  const organization = ref<Organization[]>(defaultOrganization)
  const mOrganizationId = ref<number>(defaultOrganizationId)
  const isPlatformUser = ref(defaultIsPlatform)
  const isSellerUser = ref(defaultIsSeller)
  const mOrganizationAppendTypeId = ref<string>(defaultOrganizationAppendTypeId)

  function setOrganization(list: Organization[]) {
    organization.value = list
    localStorage.setItem('organizationList', JSON.stringify(list))
  }

  const setCurrentOrganizationId = (
    id: number,
    organizationType?: string,
    organizationAppendTypeId?: string,
  ) => {
    mOrganizationId.value = id
    localStorage.setItem('organizationId', String(id))

    const isPlatform = organizationType === 'platform'
    isPlatformUser.value = isPlatform
    localStorage.setItem('isPlatformUser', JSON.stringify(isPlatform))

    // 采购组织
    const isSeller = organizationAppendTypeId === '0'
    isSellerUser.value = isSeller
    localStorage.setItem('isSellerUser', JSON.stringify(isSeller))

    mOrganizationAppendTypeId.value = organizationAppendTypeId ?? ''
    localStorage.setItem('organizationAppendTypeId', organizationAppendTypeId ?? '')
  }

  return {
    organization,
    setOrganization,
    organizationId: mOrganizationId,
    setCurrentOrganizationId,
    isPlatformUser,
    isSellerUser,
    organizationAppendTypeId: mOrganizationAppendTypeId,
  }
})
