import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const supplier: RouteRecordRaw = {
  path: '/supplier',
  component: LayoutComponent,
  redirect: '/supplier/resource-pool',
  children: [
    {
      path: '/supplier/resource-pool',
      meta: { title: '资源池管理' },
      children: [
        {
          path: '/supplier/resource-pool/scope',
          meta: { title: '资源池范围配置' },
          component: () => import('../pages/supplier/resource-pool/scope.vue'),
        },
        {
          path: '/supplier/resource-pool/main-data',
          meta: { title: '资源池主数据' },
          component: () => import('../pages/supplier/resource-pool/mainData.vue'),
        },
      ],
    },
    {
      path: '/supplier/info',
      meta: { title: '供应商信息查询' },
      children: [
        {
          path: '/supplier/info/list',
          meta: { title: '供应商清单' },
          component: () => import('../pages/supplier/info-list/index.vue'),
        },
        {
          path: '/supplier/info/investigation-form',
          meta: { title: '调查表查询' },
          component: () => import('../pages/supplier/resource-pool/scope.vue'),
        },
        // 送样表
        {
          path: '/supplier/info/sample',
          meta: { title: '送样表查询' },
          component: () => import('../pages/supplier/resource-pool/scope.vue'),
        },
        // 考察报告查询
        {
          path: '/supplier/info/report',
          meta: { title: '考察报告查询' },
          component: () => import('../pages/supplier/resource-pool/scope.vue'),
        },
        // 靠谱档案查询
        {
          path: '/supplier/info/archives',
          meta: { title: '考评档案查询' },
          component: () => import('../pages/supplier/resource-pool/scope.vue'),
        },
      ],
    },
    {
      // 供应商开发
      path: '/supplier/development',
      meta: { title: '供应商开发' },
      children: [
        // 供应商招募
        {
          path: '/supplier/development/recruit',
          meta: { title: '供应商招募' },
          component: () =>
            import('../pages/supplier/development/recruitment/recruitment-index.vue'),
        },
        // 绿色通道
        {
          path: '/supplier/development/green-channel',
          meta: { title: '绿色通道' },
          component: () =>
            import('../pages/supplier/development/green-channel/green-channel-index.vue'),
        },
        // 跨组织引入
        {
          path: '/supplier/development/cross-organization',
          meta: { title: '跨组织引入' },
          component: () =>
            import('../pages/supplier/development/cross-organization/cross-organization-index.vue'),
        },
        // 资质审核
        {
          path: '/supplier/development/qualification',
          meta: { title: '资质审核' },
          component: () =>
            import(
              '../pages/supplier/development/qualification-audit/qualification-audit-index.vue'
            ),
        },
        // 供应商录入
        {
          path: '/supplier/development/entry',
          meta: { title: '供应商录入' },
          component: () => import('../pages/supplier/development/entry/supplier-entry.vue'),
        },
        // 调查表管理
        {
          path: '/supplier/development/investigation-form',
          meta: { title: '调查表管理' },
          component: () =>
            import('../pages/supplier/development/questionnaire/questionnaire-index.vue'),
        },
        // 考察报告管理
        {
          path: '/supplier/development/report',
          meta: { title: '考察报告管理' },
          component: () =>
            import(
              '../pages/supplier/development/investigation-report/investigation-report-index.vue'
            ),
        },
        // 送样管理
        {
          path: '/supplier/development/sample',
          meta: { title: '送样管理' },
          component: () => import('../pages/supplier/development/sample-sheet/index.vue'),
        },
        // 供应商评审
        {
          path: '/supplier/development/review',
          meta: { title: '供应商评审' },
          component: () => import('../pages/supplier/resource-pool/scope.vue'),
        },
        // 品类准入配置
        {
          path: '/supplier/development/category',
          meta: { title: '品类准入配置' },
          component: () =>
            import(
              '../pages/supplier/development/category-access-configuration/category-access-configuration-index.vue'
            ),
        },
        // 认证流程配置
        {
          path: '/supplier/development/certification',
          meta: { title: '认证流程配置' },
          component: () =>
            import(
              '../pages/supplier/development/authentication-process-configuration/authentication-process-configuration-index.vue'
            ),
        },
      ],
    },
    {
      // 过程管理
      path: '/supplier/process',
      meta: { title: '过程管理' },
      children: [
        // 能力清单管理
        {
          path: '/supplier/process/capability',
          meta: { title: '能力清单管理' },
          component: () => import('../pages/supplier/process/capability/capability.vue'),
        },
        // 能力清单审核
        // {
        //   path: '/supplier/process/capability-review',
        //   meta: { title: '能力清单审核' },
        //   component: () => import('../pages/supplier/resource-pool/scope.vue'),
        // },
        // 生命周期管理
        {
          path: '/supplier/process/life-cycle/manage',
          meta: { title: '生命周期管理' },
          component: () => import('../pages/supplier/process/life-cycle/manage.vue'),
        },
        // 生命周期审核
        {
          path: '/supplier/process/life-cycle/approve',
          meta: { title: '生命周期审核' },
          component: () => import('../pages/supplier/process/life-cycle/approve.vue'),
        },
        // 品类周期管理
        {
          path: '/supplier/process/category',
          meta: { title: '品类周期管理' },
          component: () => import('../pages/supplier/process/category-cycle/manage.vue'),
        },
        // 品类周期审核
        {
          path: '/supplier/process/category-review',
          meta: { title: '品类周期审核' },
          component: () => import('../pages/supplier/process/category-cycle/approve.vue'),
        },
        // 生命周期申请表查询
        {
          path: '/supplier/process/apply',
          meta: { title: '申请表查询', skipMenu: true },
          component: () => import('../pages/supplier/process/life-cycle/record/index.vue'),
        },
        // 品类申请表查询
        {
          path: '/supplier/process/category-apply',
          meta: { title: '品类申请表查询', skipMenu: true },
          component: () => import('../pages/supplier/process/category-cycle/record/index.vue'),
        },
      ],
    },
    {
      // 供应商绩效管理
      path: '/supplier/performance',
      meta: { title: '供应商绩效管理' },
      children: [
        // 考评档案管理
        {
          path: '/supplier/performance/archives',
          meta: { title: '考评档案管理' },
          component: () => import('../pages/supplier/performance/archives/archives.vue'),
        },
      ],
    },
    {
      // 供应商退出
      path: '/supplier/exit',
      meta: { title: '供应商退出' },
      children: [
        // 黑名单管理
        {
          path: '/supplier/exit/blacklist',
          meta: { title: '黑名单管理' },
          component: () => import('../pages/supplier/exit/blacklist/blacklist.vue'),
        },
      ],
    },
  ],
}
