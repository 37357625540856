import { isEmpty, omit } from 'lodash-es'
import { GridColumnWidths, Modal } from '@skycore/skycore-ui'
import { message, type ModalFuncProps } from 'ant-design-vue'
import type { CellClickedEvent } from 'ag-grid-community'
import { t } from '@/i18n'
import type { ICustomColumnItem } from '@/types/custom'
import http from './http'

export const getApi = <T = any>(url: string, options = {}) => {
  return http<T>({
    url,
    method: 'get',
    withCredentials: true,
    ...options,
  })
}

export const postApi = <T = any>(
  url: string,
  data = {},
  options = {} as { download?: boolean },
) => {
  return http<T>(
    {
      url,
      method: 'post',
      withCredentials: true,
      data: omit(data, ['_searchOptions']),
      ...omit(options, ['download']),
    },
    options.download,
  )
}

/**
 * 根据字段去查询 tree 数据，返回符合条件的节点
 */
export const findNodeByField = <T extends Record<string, any>>(
  treeData: T[],
  field: string,
  value: any,
) => {
  let result: T | undefined
  const find = (data: T[]) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      if (item[field]?.toString() === value?.toString()) {
        result = item
        break
      }
      if (item.children) {
        find(item.children)
      }
    }
  }
  find(treeData)
  return result
}

/**
 * 将传入的值转换为 '-'，如果值为空
 * @param value string | null | undefined
 * @returns
 */
export const replaceEmptyValue = (value: unknown) => {
  console.log(value)
  if (value === null || value === undefined || isEmpty(value)) {
    return '-'
  }
  return value
}

/**
 * 将对象的空值的属性删除
 */
export const removeEmptyValue = (obj: Record<string, any>) => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach(key => {
    if (newObj[key] === null || newObj[key] === undefined || newObj[key] === '') {
      delete newObj[key]
    }
  })
  return newObj
}

export const successMessage = (info = '操作成功') => {
  message.success(info)
}
export const onSuccess = () => successMessage()

export const warningMessage = (info: string) => {
  message.warning(info)
}

export const infoMessage = (info: string) => {
  message.info(info)
}

export const errorMessage = (info: string) => message.error(info)

export const confirmAction = (props: ModalFuncProps) => {
  return Modal.confirm(props)
}

/**
 * 将 tree 的数据的字段转换为指定的字段
 */
export const transformTreeData = <T extends { children?: T[]; [key: string]: any }>(
  treeData: T[],
  options: {
    field: string
    targetField: string
  },
) => {
  const { field, targetField } = options
  if (field === targetField) return treeData
  const transform = (data: T[]) => {
    return data.map(item => {
      const value = item[field]
      const result = {
        ...item,
        [targetField]: value,
      }
      if (item?.children && item.children.length > 0) {
        result.children = transform(item.children)
      }
      return result
    })
  }
  return transform(treeData)
}

/**
 * 根据 Select 的 Options，基于 id 搜索 name
 */
export const findOptionNameById = (
  options: { id: string | number; name: string }[],
  id?: number | string,
) => {
  const option = options.find(item => item.id === id)
  return option?.name || '-'
}

/**
 * 根据 Select 的 Options，基于 name 搜索 id, 搜索失败返回 undefined
 */
export const findOptionIdByName = (
  options: { id: string | number; name: string }[],
  name?: string,
) => {
  const option = options.find(item => item.name === name)
  return option?.id
}

/**
 * 随机生成一个 UUID
 */
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * 将 tree 的数据结构的叶子节点的 disabled 属性设置为 true
 */
export const setLeafNodeDisabled = <T extends { children?: T[]; [key: string]: any }>(
  treeData: T[],
) => {
  const set = (data: T[]) => {
    return data.map(item => {
      const result = {
        ...item,
        disabled: item.children?.length !== 0,
      }
      if (item?.children && item.children.length > 0) {
        result.children = set(item.children)
      }
      return result
    })
  }
  return set(treeData)
}

export const commonPickApproveStatusColor = (label: string) => {
  const map = {
    新建: 'draft',
    待审批: 'process3',
    待确认: 'process3',
    审批中: 'process3',
    审核通过: 'success',
    审批通过: 'success',
    已确认: 'success',
    审批驳回: 'process1',
    已关闭: 'process1',
    待发布: 'process3',
  } as any
  return map?.[label] ?? 'draft'
}

/**
 * 指定对象的某个 key ，复制一个新的 key 添加到对象中
 */
export const copyKeyToNewKey = <T>(obj: T, key: string, newKey: string) => {
  const newObj: any = { ...obj }
  newObj[newKey] = newObj[key]
  return newObj as T & { [key: string]: any }
}

/**
 * 管道处理分页数据
 */
export const pageChangePipe = (
  setPageParams: (pageIndex: number, pageSize: number) => void,
  cb: () => unknown,
) => {
  return (pageIndex: number, pageSize: number) => {
    console.log('runtime params:', pageIndex, pageSize, cb)
    setPageParams(pageIndex, pageSize)
    cb()
  }
}

/**
 * table cell 点击事件
 * 简化操作列的点击事件写法
 */
export const onCellClickedWrapper =
  <T>(func: (data?: T, value?: unknown) => unknown) =>
  async ({ data, value }: CellClickedEvent<T>) => {
    func(data, value)
  }

export const onOperationCellClickedWrapper =
  <T>(func: (data?: T, value?: unknown) => unknown) =>
  async (_: unknown, { data, value }: CellClickedEvent<T>) => {
    func(data, value)
  }

/**
 * 通用的是和否选项
 */
export const commonYesNoOptions = [
  { name: '是', id: 1 },
  { name: '否', id: 0 },
]

/** 表格统一字段 */
export const appendGridDefs = <T extends { [key: string]: any }>(): ICustomColumnItem<T>[] => [
  {
    headerName: t('field.createdBy'),
    field: 'creatorName',
    width: GridColumnWidths.personName,
  },
  {
    headerName: t('field.createdAt'),
    field: 'createdTime',
    width: GridColumnWidths.dateTime,
  },
  {
    headerName: t('field.updatedBy'),
    field: 'updaterName',
    width: GridColumnWidths.personName,
  },
  {
    headerName: t('field.updatedAt'),
    field: 'updatedTime',
    width: GridColumnWidths.dateTime,
  },
]
