<template>
  <div
    class="mx-auto mb-24 flex cursor-pointer items-center justify-center rounded-md bg-black p-3 py-1 pt-1.5 text-white"
    @click.stop="enableDevMode"
    :style="{
      opacity: isDevMode ? 1 : 0.2,
    }"
  >
    DEV
  </div>
</template>

<script setup lang="ts">
const isDevMode = ref(false)

const enableDevMode = () => {
  localStorage.setItem('devMode', isDevMode.value ? 'false' : 'true')
  window.location.reload()
}

onMounted(() => {
  isDevMode.value = localStorage.getItem('devMode') === 'true'
  if (localStorage.getItem('devMode') === 'true') {
    setInterval(() => {
      const keys = Object.keys(localStorage)
      keys.forEach(key => {
        // if (key.startsWith('/sc_')) {
        //   localStorage.removeItem(key)
        // }
        if (key.startsWith('/')) {
          localStorage.removeItem(key)
        }
      })
    }, 100)
  }
})
</script>
