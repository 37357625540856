import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const order: RouteRecordRaw = {
  path: '/order',
  component: LayoutComponent,
  redirect: '/order',
  children: [
    {
      path: '/order',
      meta: { title: '订单' },
      children: [
        {
          path: '/order/order-management',
          meta: { title: '订单工作台' },
          component: () => import('../pages/order/order-management/order-management.vue'),
        },
      ],
    },
    {
      path: '/order/logistic',
      meta: { title: '物流' },
      children: [
        {
          path: '/order/logistic/delivery-management',
          meta: { title: '发货管理' },
          component: () =>
            import('../pages/order/logistic/delivery-management/delivery-management.vue'),
        },
        {
          path: '/order/logistic/receipt-management',
          meta: { title: '收货管理' },
          component: () =>
            import('../pages/order/logistic/receipt-management/receipt-management.vue'),
        },
      ],
    },
  ],
}
