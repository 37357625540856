import { createRouter, createWebHashHistory } from 'vue-router'
import LayoutComponent from '@/layout/layout-component.vue'
import { platform } from './platform'
import { buildShare } from './buildShare'
import { finance } from './finance'
import { workbench } from './workbench'
import { purchase } from './purchase'
import { sale } from './sale'
import { supplier } from './supplier'
import { contract } from './contract'
import { customer } from './customer'
import { order } from './order'
import { sourcing } from './sourcing'
import { settlement } from './settlement'

// 将复杂的多层路由配置拆分成多个文件，便于维护
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/phoneLogin',
      name: 'phoneLogin',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/chatLogin',
      name: 'chatLogin',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/wChatLogin',
      name: 'wChatLogin',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('../pages/entrance/entrance-index.vue'),
    },
    {
      path: '/supplier-signup',
      name: 'supplier-signup',
      component: () => import('../pages/entrance/supplier-signup/supplier-signup.vue'),
    },
    {
      path: '/supplier-account',
      name: 'supplier-account',
      component: () => import('../pages/entrance/supplier-signup/supplier-signup.vue'),
    },
    {
      path: '/organization',
      name: 'organization',
      component: () => import('../pages/entrance/organization-index.vue'),
      children: [
        {
          path: '/organization/join',
          name: 'organization-join',
          component: () => import('../pages/entrance/organization-index.vue'),
        },
        {
          path: '/organization/create',
          name: 'organization-create',
          component: () => import('../pages/entrance/organization-index.vue'),
        },
      ],
    },
    {
      path: '/edit-contract-template',
      name: 'editContractTemplate',
      component: () =>
        import('../pages/settings/contract-template-editor/contract-template-editor.vue'),
    },
    {
      path: '/preview-contract-template',
      name: 'previewContractTemplate',
      component: () =>
        import('../pages/settings/contract-template-editor/contract-template-editor.vue'),
    },
    {
      path: '/home',
      name: 'home',
      component: LayoutComponent,
      children: [
        {
          path: '/home/index',
          name: 'homeIndex',
          component: () => import('../pages/home/home-index.vue'),
        },
      ],
    },
    {
      path: '/information-maintenance',
      name: 'information-maintenance',
      component: LayoutComponent,
      children: [
        {
          path: '/home/information-maintenance',
          name: 'homeInformationMaintenance',
          component: () =>
            import('../pages/supplier/information-maintenance/information-maintenance-index.vue'),
        },
      ],
    },
    {
      path: '/invite',
      name: 'invite',
      component: () => import('../pages/entrance/invite-employee/invite-employee-index.vue'),
    },
    {
      path: '/invite-supplier',
      name: 'inviteSupplier',
      component: () => import('../pages/entrance/invite-supplier/invite-supplier-index.vue'),
    },
    {
      path: '/project',
      component: LayoutComponent,
      redirect: '/project/management/list',
      children: [
        {
          path: '/project/management/list',
          meta: {
            title: '项目列表',
          },
          component: () => import('../pages/project/management/list/project-list-index.vue'),
        },
      ],
    },

    {
      path: '/settings',
      component: LayoutComponent,
      redirect: '/settings/staff',
      children: [
        {
          path: '/settings/staff',
          meta: {
            title: '员工管理',
          },
          component: () => import('../pages/settings/staff/staff-index.vue'),
        },
        {
          path: '/settings/organization/self-settings',
          meta: {
            title: '个人设置',
          },
          component: () =>
            import('../pages/settings/organization/self-settings/self-settings-index.vue'),
        },
        {
          path: '/settings/organization/settlement-type',
          meta: {
            title: '结算方式',
          },
          component: () =>
            import('../pages/settings/organization/settlement-type/settlement-type-index.vue'),
        },
        {
          path: '/settings/organization/organization',
          meta: {
            title: '组织管理',
          },
          component: () =>
            import('../pages/settings/organization/organization/organization-index.vue'),
        },
        {
          path: '/settings/organization/role',
          meta: {
            title: '角色管理',
          },
          component: () => import('../pages/settings/organization/role/role-index.vue'),
        },
        {
          path: '/settings/organization/organization-menu',
          meta: {
            title: '组织菜单',
          },
          component: () =>
            import('../pages/settings/organization/organization-menu/organization-menu-index.vue'),
        },
        {
          path: '/settings/workflow/set',
          meta: {
            title: '流程设置',
          },
          component: () => import('@/pages/settings/workflow/flow-model-index.vue'),
        },
        {
          path: '/settings/contract-template',
          meta: {
            // TODO 增强合同模板功能
            title: '合同模板',
          },
          component: () =>
            import('../pages/settings/contract-template/contract-template-index.vue'),
        },
        {
          path: '/settings/sales-setup',
          meta: {
            title: '销售设置',
          },
          component: () => import('../pages/settings/sales-setup/sales-setup-index.vue'),
        },
        {
          path: '/settings/mall-set',
          meta: {
            title: '商城设置',
          },
          component: () => import('../pages/sale/transaction/mall-set/mall-set-index.vue'),
        },
        {
          path: '/settings/home-page-product',
          meta: {
            title: '首页商品设置',
          },
          component: () =>
            import('../pages/sale/transaction/home-page-product/home-page-product.vue'),
        },
        {
          path: '/settings/workflow/set',
          meta: {
            title: '流程设置',
          },
          component: () => import('@/pages/settings/workflow/flow-model-index.vue'),
        },
        {
          path: '/settings/my-coupon',
          meta: {
            title: '我的优惠券',
          },
          component: () => import('@/pages/settings/my-coupon/my-coupon-index.vue'),
        },
        {
          path: '/settings/prefer-setting',
          meta: {
            title: '偏好设置',
          },
          component: () => import('@/pages/settings/prefer-setting/prefer-setting-index.vue'),
        },
      ],
    },
    {
      path: '/material',
      component: LayoutComponent,
      redirect: '/material/material/category-attribute',
      children: [
        {
          path: '/material/material/category-attribute',
          meta: { title: '品类属性' },
          component: () =>
            import('../pages/material/material/category-attribute/category-index.vue'),
        },
        {
          path: '/material/material/private',
          meta: { title: '物料列表' },
          component: () => import('../pages/material/material/private/private-index.vue'),
        },
        {
          path: '/material/material/public',
          meta: { title: '物料公库' },
          component: () => import('../pages/material/material/public/material-public-index.vue'),
        },
        {
          path: '/material/material/device-model',
          meta: { title: '器件模型' },
          component: () => import('../pages/material/material/device-model/device-model-index.vue'),
        },
        {
          path: '/material/material/raw-material-setting',
          meta: { title: '原料设置' },
          component: () =>
            import(
              '../pages/material/material/raw-material-setting/raw-material-setting-index.vue'
            ),
        },
        {
          path: '/material/document/type',
          meta: {
            title: '文档类型',
          },
          component: () => import('../pages/material/document/type/type-index.vue'),
        },
        {
          path: '/material/document/list',
          meta: {
            title: '文档列表',
          },
          component: () => import('../pages/material/document/list/list-index.vue'),
        },
        {
          path: '/material/document/group',
          meta: {
            title: '文档分组',
          },
          component: () => import('../pages/material/document/group/group-index.vue'),
        },
        {
          path: '/material/process/process-list',
          meta: {
            title: '工序管理',
          },
          component: () => import('../pages/material/process/process-list/process-list-index.vue'),
        },
        {
          path: '/material/process/route-list',
          meta: {
            title: '工艺路线',
          },
          component: () => import('../pages/material/process/route-list/route-list-index.vue'),
        },
        {
          path: '/material/process/equipment-type',
          meta: {
            title: '设备类型',
          },
          component: () =>
            import('../pages/material/process/equipment-type/equipment-type-index.vue'),
        },
        {
          path: '/material/process/work-type',
          meta: {
            title: '工种管理',
          },
          component: () => import('../pages/material/process/work-type/work-type-index.vue'),
        },
        {
          path: '/material/brand/list',
          meta: {
            title: '品牌管理',
          },
          component: () => import('../pages/material/brand/list/brand-index.vue'),
        },
        {
          path: '/material/brand/series',
          meta: {
            title: '产品系列',
          },
          component: () => import('../pages/material/brand/series/series-index.vue'),
        },

        {
          path: '/material/brand/structure',
          meta: {
            title: '产品结构',
          },
          component: () => import('../pages/material/brand/structure/structure-index.vue'),
        },
        {
          path: '/material/bom/bom-list',
          meta: {
            title: 'BOM列表',
          },
          component: () => import('../pages/material/bom/bom-list/bom-list-index.vue'),
        },
        {
          path: '/material/material-for-hs',
          meta: { title: '恒圣物料' },
          component: () => import('../pages/material/material-for-hs/material-for-hs-index.vue'),
        },
      ],
    },
    {
      path: '/public',
      component: LayoutComponent,
      redirect: '/public/material-public/category-attribute',
      children: [
        {
          path: '/public/material-public/category-attribute',
          meta: { title: '品类属性' },
          component: () =>
            import('../pages/public/material-public/category-attribute/category-index.vue'),
        },
        {
          path: '/public/material-public/material-public',
          meta: { title: '物料公库' },
          component: () =>
            import('../pages/public/material-public/material-public/material-public-index.vue'),
        },

        {
          path: '/public/material-public/public-list',
          meta: { title: '公库列表' },
          component: () =>
            import('../pages/public/material-public/public-list/public-list-index.vue'),
        },
        {
          path: '/public/material-public/device-model',
          meta: { title: '器件模型' },
          component: () =>
            import('../pages/public/material-public/device-model/device-model-index.vue'),
        },
        {
          path: '/public/material-public/series',
          meta: { title: '系列管理' },
          component: () => import('../pages/public/material-public/series/series-index.vue'),
        },
        {
          path: '/public/material-public/brand',
          meta: { title: '品牌管理' },
          component: () => import('../pages/public/material-public/brand/brand-index.vue'),
        },
        {
          path: '/public/material-public/document',
          meta: { title: '文档管理' },
          component: () => import('../pages/public/material-public/document/document-index.vue'),
        },
      ],
    },
    sale,
    purchase,
    workbench,
    finance,
    buildShare,
    platform,
    supplier,
    contract,
    customer,
    order,
    sourcing,
    settlement,
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../pages/error-404.vue'),
    },
  ],
})

export default router
