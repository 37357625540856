import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const sale: RouteRecordRaw = {
  path: '/sale',
  component: LayoutComponent,
  redirect: '/sale/price/mpg',
  children: [
    {
      path: '/sale/price/authority-level',
      meta: { title: '权限层级' },
      component: () => import('../pages/sale/price/authority-level/authority-level-index.vue'),
    },
    {
      path: '/sale/price/mpg',
      meta: { title: 'MPG列表' },
      component: () => import('../pages/sale/price/mpg/mpg-index.vue'),
    },
    {
      path: '/sale/price/product',
      meta: { title: '产品管理' },
      component: () => import('../pages/sale/price/product/product-index.vue'),
    },
    {
      path: '/sale/price/saleable-category',
      meta: { title: '可售品类' },
      component: () => import('../pages/sale/price/saleable-category/saleable-category-index.vue'),
    },
    {
      path: '/sale/price/series-setting',
      meta: { title: '机柜系列设置' },
      component: () => import('../pages/sale/price/series-setting/series-setting-index.vue'),
    },
    {
      path: '/sale/price/customer-discount',
      meta: { title: '客户折扣' },
      component: () => import('../pages/sale/price/customer-discount/customer-discount-index.vue'),
    },
    {
      path: '/sale/price/promotion',
      meta: { title: '营销列表' },
      component: () => import('../pages/sale/price/promotion/promotion-index.vue'),
    },
    {
      path: '/sale/customer/my-customer',
      meta: { title: '我的客户' },
      component: () => import('../pages/customer/customer/my-customer/my-customer-index.vue'),
    },
    {
      path: '/sale/customer/customer-segmentation',
      meta: { title: '客户分群' },
      component: () =>
        import('../pages/customer/customer/customer-segmentation/customer-segmentation-index.vue'),
    },
    {
      path: '/sale/transaction/order-list-skycore',
      meta: { title: '订单列表（睿朴麟）' },
      component: () =>
        import('../pages/sale/transaction/order-list-skycore/order-list-skycore-index.vue'),
    },

    {
      path: '/purchase/purchase-info/shop-cart/order-settlement',
      meta: { title: '订单结算' },
      component: () =>
        import('../pages/sale/transaction/order-settlement/order-settlement-index.vue'),
    },
    {
      path: '/purchase/purchase-info/shop-cart/order-pay',
      meta: { title: '支付页面' },
      component: () => import('../pages/sale/transaction/order-pay/order-pay-index.vue'),
    },
    {
      path: '/purchase/purchase-info/shop-cart/payment-success',
      meta: { title: '订单支付' },
      component: () => import('../pages/sale/transaction/order-pay/payment-success-index.vue'),
    },
    {
      path: '/sale/transaction/warehouse-setup',
      meta: { title: '仓库设置' },
      component: () =>
        import('../pages/sale/transaction/warehouse-setup/warehouse-setup-index.vue'),
    },
    {
      path: '/sale/transaction/logistics-setup',
      meta: { title: '物流设置' },
      component: () =>
        import('../pages/sale/transaction/logistics-setup/logistics-setup-index.vue'),
    },
    {
      path: '/sale/sever-setup/serve',
      meta: { title: '服务设置' },
      component: () => import('../pages/sale/sever/serve-setup/serve-setup-index.vue'),
    },
    {
      path: '/sale/coupon/coupon',
      meta: { title: '优惠券' },
      component: () => import('../pages/sale/coupon/coupon/coupon-index.vue'),
    },
    {
      path: '/sale/coupon/coupon-record',
      meta: { title: '发放记录' },
      component: () => import('../pages/sale/coupon/send-record/send-record-index.vue'),
    },
    {
      path: '/sale/coupon/coupon-list',
      meta: { title: '优惠券列表' },
      component: () => import('../pages/sale/coupon/coupon-list/coupon-list-index.vue'),
    },
    // 商务手工单
    {
      path: '/sale/manual-order',
      meta: { title: '新建订单' },
      component: () => import('../pages/sale/manual-order/manual-order-index.vue'),
    },
    {
      path: '/sale/transaction/change-order',
      meta: { title: '订单变更单' },
      component: () => import('../pages/sale/transaction/change-order/change-order-index.vue'),
    },
  ],
}
