import http from '@/utils/http'
import type { Organization } from '@/types/common'
import type {
  BindWechatAccountVueCommand,
  IOrganizationVO,
  InvitedOrganizationVO,
  LoginData,
  RegisterData,
  ResetPassword,
  ResponseData,
} from './entrance'

export const verificationCode = (data: { phone: string }) => {
  return http({
    url: '/common/api/sms/send-verification-code',
    data,
  })
}

export const userLogin = (data: LoginData) => {
  return http<ResponseData>({
    url: '/ram/api/user/login',
    data,
  })
}

export const userRegister = (data: RegisterData) => {
  return http<ResponseData>({
    url: '/ram/api/user/register',
    data,
  })
}
export const changeOrganization = (organizationId: number) => {
  return http<ResponseData>({
    url: '/ram/api/user/change-organization',
    data: {
      organizationId,
    },
  })
}

export const listOrganization = () => {
  return http<Organization[]>({
    url: '/ram/api/organization/list-organization-by-user-id',
  })
}

export const logout = (data: { token: string }) => {
  return http({
    url: '/ram/api/user/logout',
    data,
  })
}

export const settingUserInfo = (data: { username: string; password: string }) => {
  return http({
    url: '/ram/api/user/setting-username-password',
    data,
  })
}

export const resetPassword = (data: ResetPassword) => {
  return http({
    url: '/ram/api/user/reset-password',
    data,
  })
}

export const queryLoginInfo = () => {
  return http<ResponseData>({
    url: '/ram/api/user/query-login-info',
  })
}

export const queryInviteOrganization = (token: string) => {
  return http({
    url: `/ram/api/employee/query-invited-organization?invitedToken=${token}`,
  })
}

export const queryInviteCache = (token: string) => {
  return http({
    url: `/srm/api/invitation/query-invitation-cache?invitedToken=${token}`,
  })
}

export const agreeInvited = (token: string) => {
  return http({
    url: `/ram/api/employee/agree-invited?invitedToken=${token}`,
  })
}

export const generateInvitedToken = () => {
  return http({
    url: '/ram/api/employee/generate-invited-token',
  })
}

export const invitedOrganizationList = () => {
  return http<InvitedOrganizationVO[]>({
    url: '/ram/api/employee/list-invited-organization',
  })
}

export const updateEmployeeStateId = (data: { employeeStateId: string; employeeId: number }) => {
  return http({
    url: '/ram/api/employee/update-employee-state-id',
    data,
  })
}

export const weChatQueryConfig = () =>
  http<{
    // 应用唯一标识，在微信开放平台提交应用审核通过后获得
    appid: string
    // 应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
    scope: string
    // 重定向地址，需要进行UrlEncode
    redirect_uri: string
    // 用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
    state?: string
    // 提供"black"、"white"可选，默认为黑色文字描述。详见文档底部FAQ
    theme?: string
    // 自定义样式链接，第三方可根据实际需求覆盖默认样式。详见文档底部FAQ
    href?: string
    // true：手机点击确认登录后可以在 iframe 内跳转到 redirect_uri，false：手机点击确认登录后可以在 top window 跳转到 redirect_uri。默认为 false。
    self_redirect?: boolean
    style?: string
  }>({
    url: '/ram/api/wechat/query-config',
  })

export const loginBindPhone = (data: BindWechatAccountVueCommand) => {
  return http({
    url: '/ram/api/user/bind-phone',
    data,
  })
}

export const printRelease = () => {
  // console.log(import.meta.env)
}

// platform-平台组织、seller-卖家组织(默认)、supplier-供应商组织
// organizationAppendTypeId groupId
export const apiCreateOrganization = (data: {
  organizationName: string
  organizationType?: string
  groupId?: number
  [key: string]: any
}) => {
  return http<IOrganizationVO>({
    url: '/ram/api/organization/create-user-organization',
    data,
  })
}
