import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const finance: RouteRecordRaw = {
  path: '/finance',
  component: LayoutComponent,
  redirect: '/finance/balance/deposit-account',
  children: [
    {
      path: '/finance/balance/deposit-account',
      meta: { title: '预存账户' },
      component: () => import('../pages/finance/balance/deposit-account/deposit-account-index.vue'),
    },
    {
      path: '/finance/balance/deposit-account-flow',
      meta: { title: '预存账户记录' },
      component: () =>
        import('../pages/finance/balance/deposit-account-record/deposit-account-record-index.vue'),
    },
    {
      path: '/finance/balance/payment-flow',
      meta: { title: '支付记录' },
      component: () => import('../pages/finance/balance/payment-history/payment-history-index.vue'),
    },
    {
      path: '/finance/balance/bank-flow',
      meta: { title: '银行流水记录' },
      component: () => import('../pages/finance/balance/bank-flow/bank-flow-index.vue'),
    },
    {
      path: '/finance/balance/refund-record',
      meta: { title: '退款记录' },
      component: () => import('../pages/finance/balance/refund-record/refund-record-index.vue'),
    },
    {
      path: '/finance/balance/recharge-record',
      meta: { title: '充值记录' },
      component: () => import('../pages/finance/balance/recharge-record/recharge-record-index.vue'),
    },
    {
      path: '/finance/customer-balance/deposit-account',
      meta: { title: '预存账户' },
      component: () =>
        import('../pages/finance/customer-balance/deposit-account/deposit-account-index.vue'),
    },
    {
      path: '/finance/customer-balance/deposit-account-flow',
      meta: { title: '预存账户记录' },
      component: () =>
        import(
          '../pages/finance/customer-balance/deposit-account-record/deposit-account-record-index.vue'
        ),
    },
    {
      path: '/finance/customer-balance/payment-flow',
      meta: { title: '支付记录' },
      component: () =>
        import('../pages/finance/customer-balance/payment-history/payment-history-index.vue'),
    },

    {
      path: '/finance/customer-balance/refund-record',
      meta: { title: '退款记录' },
      component: () =>
        import('../pages/finance/customer-balance/refund-record/refund-record-index.vue'),
    },
  ],
}
