import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Antd from 'ant-design-vue'
import { LicenseManager } from '@ag-grid-enterprise/core'
import './assets/less/global.less'
import { globalEventListener } from '@skycore/skycore-ui/lib/utils/'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/zh-cn'
import PageHeaderWithName from '@/components/PageHeaderWithName'
import { VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query'
import router from './router'
import directives from './directives'
import i18n from './i18n'
import './assets/css/main.css'
import App from './App.vue'

dayjs.extend(duration)
dayjs.locale('zh-cn')

globalEventListener()

LicenseManager.setLicenseKey(
  'DownloadDevTools_COM_NDEwMjM0NTgwMDAwMA==59158b5225400879a12a96634544f5b6',
)

const app = createApp(App)
app.use(Antd)
app.use(directives)
app.use(i18n)
app.use(createPinia())
app.use(router)
app.use(PageHeaderWithName)

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  },
}

app.use(VueQueryPlugin, vueQueryPluginOptions)

app.mount('#app')
