import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const sourcing: RouteRecordRaw = {
  path: '/sourcing',
  component: LayoutComponent,
  redirect: '/sourcing/inquiry',
  children: [
    {
      path: '/sourcing/inquiry',
      meta: { title: '询比价工作台' },
      children: [
        {
          path: '/sourcing/inquiry/list',
          meta: { title: '询比价管理' },
          component: () => import('../pages/sourcing/inquiry/inquiry-index.vue'),
        },
        {
          path: '/sourcing/quotation/list',
          meta: { title: '报价管理' },
          component: () => import('../pages/sourcing/quotation/quotation-index.vue'),
        },
      ],
    },
    {
      path: '/sourcing/bidding',
      meta: { title: '竞价工作台' },
      children: [
        {
          path: '/sourcing/bidding/list',
          meta: { title: '竞价管理' },
          component: () => import('../pages/sourcing/bidding/bidding-index.vue'),
        },
        {
          path: '/sourcing/bidding-coll/list',
          meta: { title: '竞价协同' },
          component: () => import('../pages/sourcing/bidding-coll/bidding-coll-index.vue'),
        },
      ],
    },
    {
      path: '/sourcing/tender',
      meta: { title: '招议标工作台' },
      children: [
        {
          path: '/sourcing/tender/list',
          meta: { title: '招议标管理' },
          component: () => import('../pages/sourcing/tender/tender-index.vue'),
        },
        {
          path: '/sourcing/bid/list',
          meta: { title: '投标管理' },
          component: () => import('../pages/sourcing/bid/bid-index.vue'),
        },
      ],
    },
  ],
}
