import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const contract: RouteRecordRaw = {
  path: '/contract',
  component: LayoutComponent,
  redirect: '/contract/buy/agreement-model',
  children: [
    {
      path: '/contract/buy',
      meta: { title: '采购合同' },
      children: [
        {
          path: '/contract/buy/agreement-model',
          meta: { title: '协议模板' },
          component: () =>
            import('../pages/settings/contract-template/contract-template-index.vue'),
        },
        {
          path: '/contract/buy/agreement-management',
          meta: { title: '协议管理' },
          component: () => import('../pages/contract/buy/agreement-management/index.vue'),
        },
      ],
    },
    {
      path: '/contract/price-center',
      meta: { title: '价格中心' },
      children: [
        {
          path: '/contract/price-center/price-catalog',
          meta: { title: '价格目录' },
          component: () => import('../pages/contract/price-center/price-catalog.vue'),
        },
        {
          path: '/contract/price-center/price-approve',
          meta: { title: '价格审批' },
          component: () => import('../pages/contract/price-center/price-approve.vue'),
        },
        {
          path: '/contract/price-center/price-expiration',
          meta: { title: '失效预警' },
          component: () => import('../pages/contract/price-center/price-expiration.vue'),
        },
      ],
    },
  ],
}
