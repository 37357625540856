import LayoutComponent from '@/layout/layout-component.vue'
import type { RouteRecordRaw } from 'vue-router'

export const purchase: RouteRecordRaw = {
  path: '/purchase',
  component: LayoutComponent,
  redirect: '/purchase/requirement',
  children: [
    {
      path: '/purchase/requirement',
      meta: { title: '采购需求' },
      children: [
        {
          path: '/purchase/requirement/purchase-rule',
          meta: { title: '品类分工规则' },
          component: () =>
            import('../pages/purchase/requirement/purchase-rule/purchase-rule-index.vue'),
        },
        {
          path: '/purchase/requirement/purchase-requisition',
          meta: { title: '采购申请管理' },
          component: () =>
            import(
              '../pages/purchase/requirement/purchase-requisition/purchase-requisition-index.vue'
            ),
        },
        {
          path: '/purchase/requirement/requirement-alteration',
          meta: { title: '需求变更管理' },
          component: () =>
            import(
              '../pages/purchase/requirement/requirement-alteration/requirement-alteration-index.vue'
            ),
        },
        {
          path: '/purchase/requirement/requirement-execute',
          meta: { title: '需求执行管理' },
          component: () =>
            import(
              '../pages/purchase/requirement/requirement-execute/requirement-execute-index.vue'
            ),
        },
        {
          path: '/purchase/requirement/purchase-demand-pool',
          meta: { title: '需求池管理' },
          component: () =>
            import(
              '../pages/purchase/requirement/purchase-demand-pool/purchase-demand-pool-index.vue'
            ),
        },
      ],
    },

    {
      path: '/purchase/transaction/order-list-customer',
      meta: { title: '组件采购单' },
      component: () =>
        import('../pages/purchase/purchase-info/order-list-customer/order-list-customer-index.vue'),
    },
    {
      path: '/purchase/purchase-info/shop-cart',
      meta: { title: '愿望清单' },
      component: () => import('../pages/purchase/purchase-info/shop-cart/shop-cart-index.vue'),
    },
    {
      path: '/purchase/series-model-select',
      meta: { title: '组件选型' },
      component: () => import('../pages/shopping/series/series-model-select-index.vue'),
    },
    {
      path: '/purchase/accessories-select',
      meta: { title: '配件选型' },
      component: () => import('../pages/shopping/series/accessory-model-select-index.vue'),
    },
    {
      path: '/purchase/accessories-detail',
      meta: { title: '配件详情' },
      component: () => import('../pages/shopping/series/accessory-detail-index.vue'),
    },
    {
      path: '/purchase/order',
      meta: { title: '采购订单' },
      component: () => import('../pages/purchase/order/index.vue'),
    },
    {
      path: '/purchase/order-change',
      meta: { title: '采购订单变更' },
      component: () => import('../pages/purchase/order-change/index.vue'),
    },
    {
      path: '/purchase/order-report-table',
      meta: { title: '采购执行报表' },
      component: () => import('../pages/purchase/order-report-table/index.vue'),
    },
    {
      path: '/purchase/logistic',
      meta: { title: '物流' },
      children: [
        {
          path: '/purchase/logistic/receipt-management',
          meta: { title: '收货管理' },
          component: () =>
            import('../pages/purchase/logistic/receipt-management/receipt-management.vue'),
        },
        {
          path: '/purchase/logistic/returns-management',
          meta: { title: '退货管理' },
          component: () =>
            import('../pages/purchase/logistic/returns-management/returns-management.vue'),
        },
      ],
    },
  ],
}
